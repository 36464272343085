import React, { useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CloseIcon from "@mui/icons-material/Close";
import { Zoom } from "@mui/material";
import { Text, ClearCartModal } from "@components";
import { areCurrenciesSame } from "@utils";
import { removeTeetime, editCart } from "@redux/booking/slice";
import { setBookingDetails } from "@redux/teetime/slice";
import { getListingBookings } from "@redux/booking/actions";
import closeBtn from "@assets/images/deleteIcon.png";
import closeIcon from "@assets/images/closeIcon.png";
import "./style.css";

function CartModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { addToCartData } = props;
  const { cartDetails } = useSelector((store) => store.bookingReducer);
  const { clubId } = useSelector((store) => store?.teetimeReducer);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      clearCartModal: false,
      sorting_CartData: [],
    }
  );

  useEffect(() => {
    let bookingData;
    if (cartDetails && cartDetails?.length > 0) {
      let arr = [...cartDetails];
      bookingData = arr.sort((a, b) => {
        const dateA = new Date(a?.teetime);
        const dateB = new Date(b?.teetime);
        return dateA - dateB;
      });
      setState({ sorting_CartData: bookingData });
    }
  }, [cartDetails]);

  let prices =
    state.sorting_CartData && state.sorting_CartData?.length > 0
      ? state.sorting_CartData.map((a) => a?.amount)
      : "";
  let totalPrice =
    state.sorting_CartData && state.sorting_CartData?.length > 0 && prices
      ? prices.reduce((acc, curr) => acc + curr)
      : "";

  let totalAmountArray = [];

  if (state.sorting_CartData && state.sorting_CartData?.length > 0) {
    let totalAmounts = {};

    state.sorting_CartData?.forEach((item) => {
      if (totalAmounts[item?.currency]) {
        totalAmounts[item?.currency] += item?.amount;
      } else {
        totalAmounts[item?.currency] = item?.amount;
      }
    });

    totalAmountArray = Object.keys(totalAmounts).map((currency) => ({
      amount: totalAmounts[currency],
      currency: currency,
    }));
  } else {
    totalAmountArray = [];
  }

  const changePlayers = (res, item) => {
    let localdata = JSON.parse(localStorage.getItem("LocalCartData"));
    let upd_obj = localdata?.map((obj) => {
      if (obj?.teeId === item?.teeId && obj?.teetime === item?.teetime) {
        obj.players = Number(res);
        obj.amount = item?.allPrice[Number(res) - 1]?.price?.amount;
        obj.currency = item?.allPrice[Number(res) - 1]?.price?.currency;
      }
      return obj;
    });
    dispatch(editCart(upd_obj));
  };

  const removeItem = (e) => {
    let remove_cart = cartDetails?.filter(
      (item) => item?.teeId !== e?.teeId || item?.teetime !== e?.teetime
    );
    dispatch(removeTeetime(remove_cart));
    if (cartDetails?.length === 1) {
      props.closeModal();
      if (window.location.pathname === "/checkout") {
        navigate(-1);
      }
    }
  };

  const check_Booking = () => {
    if (state.sorting_CartData.length > 0) {
      let formDate = moment(state.sorting_CartData[0]?.teetime)
        .subtract(1, "day")
        .format("DD-MM-YYYY");
      let toDate = moment(
        state.sorting_CartData[state.sorting_CartData.length - 1]?.teetime
      )
        .add(1, "day")
        .format("DD-MM-YYYY");

      let bookingsApiData = {
        fromDate: formDate,
        toDate: toDate,
      };

      dispatch(getListingBookings(bookingsApiData)).then((res) => {
        if (res?.payload?.data?.data?.data && res?.payload?.status === 200) {
          let listingData = res?.payload?.data?.data?.data;
          let result = [];
          let updateValue = [];
          state.sorting_CartData?.map((r) => {
            result = result.concat(
              listingData?.filter((e) => {
                return (
                  e?.teetime === r?.teetime &&
                  (e?.status === "BOOKED" || e?.status === "CREATED")
                );
              })
            );

            let totalPayers = result.reduce(
              (total, currentValue) => total + currentValue.players,
              0
            );

            updateValue =
              result?.length > 0 &&
              (totalPayers >= 4 || 4 - totalPayers < r?.players)
                ? updateValue.concat({ ...r, isBooked: true })
                : updateValue.concat({ ...r, isBooked: false });

            if (
              result?.length > 0 &&
              (totalPayers >= 4 || 4 - totalPayers < r?.players)
            ) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  };

  const goCheckout = () => {
    const data = {
      bookings: [...cartDetails],
      amountPaid: totalPrice,
      clubId: clubId,
    };
    let currentTimeFilter = _.filter(cartDetails, function (e) {
      return moment().isSameOrAfter(e?.teetime);
    });

    if (areCurrenciesSame(cartDetails)) {
      if (currentTimeFilter?.length > 0) {
        toast.error(t("teeTime.remove_Past_Tees"), {});
      } else if (check_Booking()) {
        toast.error(t("teeTime.teeTime_is_Book"), {});
      } else {
        props.closeModal();
        dispatch(setBookingDetails(data));
        navigate("/checkout");
      }
    } else {
      toast.error(t("teeTime.EURO_Supporte"), {});
    }
  };

  const closeClearCartModal = () => {
    setState({ clearCartModal: false });
    props.closeModal();
    if (window.location.pathname === "/checkout") {
      navigate(-1);
    }
  };

  const Title = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full h-auto my-4">
        <Text className="text-2xl text-black0 font-bold font-sfprodisplay text-center">
          {t("teeTime.teeTimeCart")}
        </Text>
        <button
          onClick={props.closeModal}
          className="w-[40px] h-[40px] bg-btnTextColor rounded-full flex items-center justify-center opacity-80 hover:opacity-100"
        >
          <img src={closeIcon} className="w-[35px] h-[35px]" alt="" />
        </button>
      </div>
    );
  };

  const DetailText = (props) => {
    return (
      <div className={`${props.divClass}`}>
        <p className={` ${props.txtClass}`}>{props.value}</p>
      </div>
    );
  };

  const BookingDetails = () => {
    return (
      <div className="w-full h-auto overflow-y-auto custom-scroll">
        {state.sorting_CartData?.length > 0 &&
          state.sorting_CartData?.map((item, index) => (
            <React.Fragment key={`teeTimeDAta`}>
              <div className="flex flex-col py-2 px-[7px] bg-[#e6e6e6] mb-2 rounded-md">
                <div className="w-full h-auto flex flex-row items-center justify-between">
                  <div className="w-auto h-auto flex flex-col">
                    <div className="flex flex-row items-center justify-start gap-x-2">
                      <DetailText
                        divClass="w-full truncate"
                        txtClass="font-semibold sm:text-[16px] text-[14px] truncate"
                        value={item?.clubName}
                      />
                    </div>
                    <DetailText
                      divClass=""
                      txtClass="font-medium sm:text-[14px] text-[13px]"
                      value={moment(item?.teetime).format("DD/MM/YY - HH:mm")}
                    />
                  </div>
                  <Tooltip title={t("teeTime.remove_Tee")} arrow>
                    <button onClick={() => removeItem(item)}>
                      <img
                        src={closeBtn}
                        alt={"close"}
                        className="w-[20px] h-[20px]"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div className="w-full h-[1px] opacity-30 bg-[gray] my-2"></div>

                <div className="w-full h-auto flex flex-row items-center justify-between">
                  <div className="w-auto h-auto flex flex-col gap-y-[4px]">
                    <DetailText
                      txtClass="opacity-70 sm:text-[14px] text-[13px]"
                      value={t("teeTime.hole")}
                    />
                    <DetailText
                      txtClass="sm:text-[14px] text-[13px]"
                      value={item?.holes}
                    />
                  </div>
                  <div className="w-auto h-auto flex flex-col gap-y-[4px]">
                    <DetailText
                      txtClass="opacity-70 sm:text-[14px] text-[13px]"
                      value={t("teeTime.player")}
                    />
                    <select
                      id="player"
                      className="w-auto hover:cursor-pointer"
                      defaultValue={item?.players}
                      onChange={(e) => changePlayers(e?.target?.value, item)}
                    >
                      {Array.from(
                        Array(item?.availablePlayer || 4),
                        (_, index) => index + 1
                      )?.map((e) => (
                        <option className="text-center">{e}</option>
                      ))}
                    </select>
                  </div>
                  <div className="w-auto h-auto flex flex-col gap-y-[4px]">
                    <DetailText
                      txtClass="opacity-70 sm:text-[14px] text-[13px]"
                      value={t("teeTime.price")}
                    />
                    <div>
                      <sup className="font-textColor lg:text-[11px] text-[9px]">
                        {item?.currency?.length > 1
                          ? getSymbolFromCurrency(item?.currency)
                          : item?.currency}
                      </sup>
                      <span className="text-[16px] font-textColor">
                        {Math.round(item?.amount)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <Line />
              {state.sorting_CartData?.length - 1 === index ? (
                <div className="w-full h-auto flex flex-row items-center pb-4 px-2">
                  <div className="w-[50%] h-auto">
                    <p className="font-semibold">{t("teeTime.total")}</p>
                  </div>
                  <div className="w-[50%] h-auto text-end">
                    {totalAmountArray?.map((amount, index2) => (
                      <div
                        className="w-full flex flex-col gap-y-2"
                        key={index2}
                      >
                        <div>
                          <sup className="font-bold lg:text-[11px] text-[9px]">
                            {getSymbolFromCurrency(amount?.currency)}
                          </sup>
                          <span className="text-[16px] font-bold">
                            {Math.round(amount?.amount)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
      </div>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      {addToCartData?.length > 0 ? (
        <Content>
          <div className="w-full h-auto flex flex-row justify-between items-start pt-6 pb-6">
            <p className={titleTxt}>{t("teeTime.teeTime_Cart")}</p>
            <button onClick={() => setState({ clearCartModal: true })}>
              <span className="text-[15px] font-semibold text-[#004996] hover:underline underline-offset-2">
                {t("teeTime.clearCart")}
              </span>
            </button>
          </div>

          <div>
            <BookingDetails />
          </div>
          {window.location.pathname === "/checkout" ? (
            <></>
          ) : (
            <>
              <div className="w-full h-auto flex items-center justify-center pt-4">
                <button
                  className="w-full md:px-6 px-[3px] rounded-full h-auto bg-primary py-2 hover:opacity-70"
                  onClick={() => goCheckout()}
                >
                  <span className="text-[18px] font-bold text-btnTextColor">
                    {t("teeTime.checkout_Pay")}
                  </span>
                </button>
              </div>

              <div className="w-full h-auto flex flex-row items-center justify-center gap-x-[2px] mt-[10px]">
                <Text className="text-sm text-white255 font-sfprodisplay text-center"></Text>
                <button onClick={props.closeModal}>
                  <span className="text-[#004996] txt-underline-animation font-bold">
                    {t("teeTime.continue")}
                  </span>
                </button>
              </div>
            </>
          )}

          <ClearCartModal
            openModal={state.clearCartModal}
            closeModal={() => closeClearCartModal()}
          />
        </Content>
      ) : (
        <Content className="flex flex-col items-center justify-center">
          <Title />
          <button className="w-full h-auto p-2" onClick={props.closeModal}>
            {t("teeTime.no_Item")}
          </button>
        </Content>
      )}
    </Dialog>
  );
}

const Content = tw.div`
md:w-[500px] sm:w-[430px] xsm:w-[320px] w-full 
min:w-[280px]
h-full
md:px-8 px-2
pb-6
md:overflow-x-hidden
sm:overflow-x-hidden
overflow-x-auto
overflow-y-auto
custom-scroll
overflow-auto
bg-btnTextColor
rounded-lg
`;
const Line = tw.div`
w-full
h-[1.1px] 
opacity-70
bg-[gray]
my-4
`;
const titleTxt = `
text-[21px] 
text-textColor
font-bold 
`;

export default CartModal;
