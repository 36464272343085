const theme = {
  dark: false,
  colors: {
    primary: "#019442",
    background: "rgb(242, 242, 242)",
    textColor: "rgb(0, 0, 0)",
    btnTextColor: "rgb(255,255,255)",
    card: "rgb(255, 255, 255)",
    text: "rgb(0, 0, 0)",
    notification: "rgb(255, 69, 58)",
  },
};

export default theme;
