import { createSlice } from "@reduxjs/toolkit";
import {
  bookingTeetime,
  getListingBookings,
  createCustomer,
  createPpayment,
  paymentUpdateBooking,
  getFacilities,
  addFavClub,
  getFavClub,
  getPartnerFacility,
  getOTP,
  getPaymentDetail,
  cancelBooking,
} from "./actions";

const initialState = {
  cartDetails: [],
  totalAmount: 0,
  currency: null,
  payLoader: false,
  favClubData: [],
  partnerFacilityData: [],
  userOTP: "",
  cancelDetails: "",
  paymentDetails: [],
  addFavClubs: null,
};
const slice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    updateCart: (state, action) => {
      if (action?.payload?.localSave) {
        state.cartDetails = action?.payload?.localdata;
      } else if (action?.payload?.removeItem) {
        state.cartDetails = [];
        localStorage.setItem("LocalCartData", JSON.stringify([]));
      } else {
        localStorage.setItem(
          "LocalCartData",
          JSON.stringify([...state.cartDetails, action.payload])
        );
        state.cartDetails = [...state.cartDetails, action.payload];
        localStorage.setItem(
          "AddToCartsData",
          JSON.stringify(state.cartDetails)
        );
      }
    },
    editCart: (state, action) => {
      state.cartDetails = action.payload;
      localStorage.setItem("LocalCartData", JSON.stringify(action.payload));
    },
    removeTeetime: (state, action) => {
      state.cartDetails = action.payload;
      localStorage.setItem("LocalCartData", JSON.stringify(action.payload));
      localStorage.setItem("AddToCartsData", JSON.stringify(action.payload));
    },
    setTotalAmount: (state, action) => {
      state.totalAmount = state.totalAmount + action?.payload?.amount;
      state.currency = action?.payload?.currency;
    },
    setPayLoader: (state, action) => {
      state.payLoader = action;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(bookingTeetime.fulfilled, (state, action) => {});
    builder.addCase(getListingBookings.fulfilled, (state, action) => {});
    builder.addCase(createCustomer.fulfilled, (state, action) => {});
    builder.addCase(createPpayment.fulfilled, (state, action) => {});
    builder.addCase(paymentUpdateBooking.fulfilled, (state, action) => {});
    builder.addCase(getFacilities.fulfilled, (state, action) => {});
    builder.addCase(addFavClub.fulfilled, (state, action) => {
      action?.payload?.status === 200 &&
        (state.addFavClubs = action?.payload?.data);
    });
    builder.addCase(getFavClub.fulfilled, (state, action) => {
      action?.payload?.status === 200 &&
        (state.favClubData = action?.payload?.data);
    });
    builder.addCase(getPartnerFacility.fulfilled, (state, action) => {
      action?.payload?.status === 200 &&
        (state.partnerFacilityData = action?.payload?.data);
    });
    builder.addCase(getOTP.fulfilled, (state, action) => {
      action?.payload?.status === 200 &&
        (state.userOTP = action?.payload?.data);
    });
    builder.addCase(getPaymentDetail.fulfilled, (state, action) => {
      action?.payload?.status === 200 &&
        (state.paymentDetails = action?.payload?.data);
    });
    builder.addCase(cancelBooking.fulfilled, (state, action) => {
      action?.payload?.status === 200 &&
        (state.cancelDetails = action?.payload?.data);
    });
  },
});
export const {
  updateCart,
  setTotalAmount,
  removeTeetime,
  editCart,
  setPayLoader,
} = slice.actions;

export default slice.reducer;
