export const getAverageTeeTime = (item) => {
  let all_Tee_Time_cachings = [];
  let startTee;
  let endTee;
  let startPrice;
  let endPrice;

  item?.tee_times_cachings?.map((e) => {
    all_Tee_Time_cachings = all_Tee_Time_cachings?.concat(e?.teeTimes);
  });

  if (all_Tee_Time_cachings && all_Tee_Time_cachings?.length) {
    startTee = all_Tee_Time_cachings[0];
    endTee = all_Tee_Time_cachings[all_Tee_Time_cachings?.length - 1];
    if (
      startTee?.products[0]?.pricing[0]?.publicRate?.amount <
      endTee?.products[0]?.pricing[0]?.publicRate?.amount
    ) {
      startPrice = startTee?.products[0]?.pricing[0]?.publicRate?.amount;
      endPrice = endTee?.products[0]?.pricing[0]?.publicRate?.amount;
    } else if (
      startTee?.products[0]?.pricing[0]?.publicRate?.amount ==
      endTee?.products[0]?.pricing[0]?.publicRate?.amount
    ) {
      startPrice = startTee?.products[0]?.pricing[0]?.publicRate?.amount;
      endPrice = "";
    } else {
      startPrice = endTee?.products[0]?.pricing[0]?.publicRate?.amount;
      endPrice = startTee?.products[0]?.pricing[0]?.publicRate?.amount;
    }
  }

  const data = {
    startTeeTime: startTee,
    lastteeTime: endTee,
    startPrice: startPrice,
    lastPrice: endPrice,
    tees: all_Tee_Time_cachings,
  };
  return data;
};
