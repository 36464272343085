import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Storage } from "@utils";
import { deleteUserData } from "@redux/user/slice";
import { setUserDetails } from "@redux/authentication/slice";

export default function DropDown(props) {
  const dispatch = useDispatch();
  const [dropIcon, setDropIcon] = useState(false);
  const { t } = useTranslation("common")

  const handleLogout = async (e) => {
    await Storage.remove(Storage.AUTHENTICATION);
    await Storage.remove(Storage.ADD_TO_CARTS_DATA);
    await Storage.remove(Storage.USER_TOKEN);
    await Storage.remove(Storage.USER_DATA);
    await Storage.remove(Storage.RESET_TOKEN);
    dispatch(deleteUserData(null));
    dispatch(setUserDetails(""));
  };
  const ArrowUp = () => {
    return <ChevronUpIcon className="h-6 w-6 text-btnTextColor" aria-hidden="true" />;
  };
  const ArrowDown = () => {
    return (
      <ChevronDownIcon className="h-6 w-6 text-btnTextColor" aria-hidden="true" />
    );
  };

  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <div className="w-[60px] h-[60px] rounded-full ring-2 ring-primary">
          <img
            alt=""
            src={props.image}
            style={{ width: 60, height: 60, color: "white", borderRadius: "50%" }}
          />
        </div>
        <span
          className={`text-[17px] capitalize font-sans font-medium text-btnTextColor ${props.titleStyle} ml-2`}
        >
          {t(`${props.title}`)}
        </span>
        {dropIcon === false ? <ArrowDown /> : <ArrowUp />}
      </Popover.Button>
      <Transition
        beforeLeave={() => setDropIcon(false)}
        beforeEnter={() => setDropIcon(true)}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-4">
          <div className="w-36  shrink rounded-xl bg-btnTextColor p-3 text-sm font-semibold leading-6 text-gray-900 ring-1 ring-gray-900/5 shadow-[0px_4px_5px_0px_rgba(229,226,255,0.80)]">
            {props.options.map((item, index) => (
              <>
                <NavLink
                  key={item.value}
                  to={item.value}
                  className="block p-2 hover:text-primary text-[18px] font-sans font-medium"
                  onClick={
                    item.value === "/login" && item.index === 2
                      ? handleLogout
                      : null
                  }
                >
                  {item.title}
                </NavLink>
                {props.options.length - 1 === index ? (
                  <></>
                ) : (
                  <div className="w-full h-[1px] bg-[#DDDDDD]"></div>
                )}
              </>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
