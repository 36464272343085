import moment from "moment";
import _ from "lodash";

export function filterTeeTimeData(props) {
  if (props) {
    let currentTimeFilter;

    currentTimeFilter = _.filter(props, function (e) {
      if (moment(e?.time).format("D") === moment().format("D")) {
        return moment(e?.time).format("HHmm") > moment().format("HHmm");
      } else {
        return e;
      }
    });

    return currentTimeFilter;
  }
}
