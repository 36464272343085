import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "@redux/authentication/slice";
import updateUserReducer from "@redux/user/slice";
import headerReducer from "@redux/header/slice";
import teetimeReducer from "@redux/teetime/slice";
import newsReducer from "@redux/news/slice";
import bookingReducer from "@redux/booking/slice";
import searchReducer from "@redux/search/slice";
import userReducer from "@redux/user/slice";
import settingsReducer from "@redux/settings/slice";

const rootReducer = combineReducers({
  authReducer,
  headerReducer,
  teetimeReducer,
  newsReducer,
  bookingReducer,
  searchReducer,
  userReducer,
  settingsReducer,
  updateUserReducer,
});

export default rootReducer;
