import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useGeolocated } from "react-geolocated";
import { Footer } from "@components";
import { Storage } from "@utils";
import ErrorPage from "@pages/errorPage";
import {
  setCoordinates,
  upsertRecentSearches,
  upsertRecentViewedNews,
  setDefaultCountryCoordinates,
} from "@redux/user/slice";
import {
  getTerms,
  getSetting,
  getAbout_US,
  getPrivacyData,
  getHomePageSetting,
  getCancellationPolicy,
} from "@redux/settings/actions";
import { getUser } from "@redux/user/actions";
import { change_Language } from "@redux/settings/slice";
import { getCountrysList } from "@redux/teetime/actions";
import { setUserDetails } from "@redux/authentication/slice";
import { getMenus, getHeaderData } from "@redux/header/actions";
import routes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation("common");
  const { setting } = useSelector((store) => store.settingsReducer);
  const defaultCountry =
    setting?.data?.attributes?.country?.data?.attributes?.name;
  const { current_Language, homePageSetting } = useSelector(
    (store) => store.settingsReducer
  );
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const { menus, logo } = useSelector((state) => state.headerReducer);
  const { countryList } = useSelector((store) => store.teetimeReducer);

  const [isServerError, setIsServerError] = useState(false);
  const [isServerLoading, setIsServerLoading] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    if (token) {
      navigate(`/reset-password?token=${token}`);
    }
  }, [location]);

  useEffect(() => {
    const initialize = async () => {
      let recentSearches = await Storage.get(Storage.RECENT_SEARCHES);
      let recentViewedNews = await Storage.get(Storage.RECENT_VIEWED_NEWS);
      if (recentSearches) {
        let parsedRecentSearches = JSON.parse(recentSearches);
        dispatch(upsertRecentSearches(parsedRecentSearches));
      }
      if (recentViewedNews) {
        let parsedRecentViewedNews = JSON.parse(recentViewedNews);
        dispatch(upsertRecentViewedNews(parsedRecentViewedNews));
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      if (coords.latitude && coords.longitude) {
        axios
          .get(
            `https://api.openweathermap.org/data/2.5/weather?lat=${
              coords.latitude
            }&lon=${
              coords.longitude
            }&cnt=15&units=metric&appid=${"c164ad065360bac38905bb4be3d5dd19"}`
          )
          .then((response) => {
            if (response?.data?.sys?.country) {
              let regionNames = new Intl.DisplayNames(["en"], {
                type: "region",
              });
              let countryName = regionNames.of(response?.data?.sys?.country);
              dispatch(
                setCoordinates({
                  lat: coords.latitude,
                  lng: coords.longitude,
                  country: countryName,
                })
              );
            } else {
              dispatch(
                setCoordinates({
                  lat: coords.latitude,
                  lng: coords.longitude,
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              setCoordinates({
                lat: coords.latitude,
                lng: coords.longitude,
              })
            );
          });
      }
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords]);

  useEffect(() => {
    async function checkLanguage() {
      try {
        let selectLanguage = await Storage.get(Storage.LANGUAGE);
        let new_Language = selectLanguage.replace(/"/g, "");
        i18n.changeLanguage(new_Language);
        if (current_Language === new_Language) {
          dispatch(getTerms(`terms-cond?locale=${new_Language}`));
          dispatch(getPrivacyData(`privacy-policy?locale=${new_Language}`));
          dispatch(
            getCancellationPolicy(`cancellation-policy?locale=${new_Language}`)
          );
          dispatch(getAbout_US(`about-us?locale=${new_Language}`));
        } else {
          dispatch(change_Language(new_Language));
        }
      } catch (error) {
        dispatch(getTerms(`terms-cond?locale=${current_Language}`));
        dispatch(getPrivacyData(`privacy-policy?locale=${current_Language}`));
        dispatch(
          getCancellationPolicy(
            `cancellation-policy?locale=${current_Language}`
          )
        );
        dispatch(getAbout_US(`about-us?locale=${current_Language}`));
      }
    }

    checkLanguage();
  }, [current_Language]);

  useEffect(() => {
    dispatch(getMenus());
    dispatch(getSetting());
    dispatch(getHeaderData());
    checkUserAuthentication();
    dispatch(getCountrysList());
    dispatch(getHomePageSetting(current_Language));

    // if (localStorage?.UserData) {
    //   const userData = localStorage.getItem("UserData");
    //   dispatch(getUser(JSON.parse(localStorage?.UserData)?.id));
    // }

    const userData = localStorage.getItem("UserData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        if (parsedData && parsedData.id) {
          dispatch(getUser(parsedData.id));
        }
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    if (defaultCountry) {
      const fetchGeolocation = async () => {
        try {
          const response = await fetch(
            `https://api.opencagedata.com/geocode/v1/json?q=${defaultCountry}&key=1c9a9c1c266d47acb66de1b1b354c6e5`
          );
          const data = await response.json();
          if (data?.results?.length) {
            dispatch(
              setDefaultCountryCoordinates({
                lat: data?.results[0]?.geometry?.lat,
                lng: data?.results[0]?.geometry?.lng,
              })
            );
          }
        } catch (error) {}
      };

      fetchGeolocation();
    }
  }, [defaultCountry]);

  useEffect(() => {
    if (
      !homePageSetting?.length &&
      !setting?.data?.id &&
      !menus?.data?.length &&
      !logo?.data?.id &&
      !countryList?.length
    ) {
      setIsServerLoading(true);
    } else {
      setIsServerError(false);
      setIsServerLoading(false);
    }
  }, [homePageSetting, menus, logo, setting, countryList]);

  useEffect(() => {
    let interval = null;

    if (isServerLoading) {
      interval = setInterval(() => {
        setIsServerError(true);
      }, 5000);
      return () => clearInterval(interval);
    } else {
      clearInterval(interval);
      setIsServerError(false);
    }
  }, [isServerLoading]);

  const checkUserAuthentication = async () => {
    let userDetails = await Storage.get(Storage.AUTHENTICATION);
    if (userDetails) {
      let user = JSON.parse(userDetails);
      dispatch(setUserDetails(user));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isServerError ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "white",
          }}
        >
          <div>
            <h1
              style={{
                marginTop: "50px",
                color: "gray",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Welcome To GolfClix{" "}
            </h1>
          </div>
          <div
            style={{
              marginTop: "50px",
              color: "#019442",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            <h1 className="animate-pulse ">Server Under Maintenance </h1>
          </div>
        </div>
      ) : isServerLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <svg className="w-16 h-16 bg-transparent border-4 animate-spin rounded-full border-t-[white] border-[#019442]" />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <Routes>
            {routes.map((item, index) => (
              <Route
                key={"" + index}
                path={item.path}
                exact={item.exact}
                name={item.name}
                element={item.element}
              />
            ))}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default App;
